/* ---- particles.js container ---- */

#particles-js {
  background-color: #103ea9;
}

.App-particles__container {
  position: absolute;
  top: 160px;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 1; /* if you use -1 you have to set to `"window"` the interactivity.detectsOn property */
}
